@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*
{
  font-family: 'Roboto';
  padding: 0;
  margin: 0;
  transition: all .25s; 
  scroll-behavior: smooth;
}
body{
  transition: all ease-in-out .2s;  
}
.logo
{
  height: 50px;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
}
.header
{ 
  background-color: #7e8b9e00;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  position: fixed;
  top: 30px;
  width: 100%;
  border-bottom-left-radius: 10px;  
  border-bottom-right-radius: 15px;  
  z-index: 3;
}
a{text-decoration: none;}
.navButtons
{

  width:100%;
  display: grid;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr;
  align-self: center;
  justify-self: center;
  text-align: center;
  text-shadow: black 1px 1px;
  text-decoration: none;
}
.tituloP
{
  font-size: 30px;
}
.miYVi
{
  padding: 50px;
  width: 70%;
  margin: auto;
  text-align: center;
  font-size: 35px;
  font-weight: bolder;
  color:#88be47;
  -webkit-align-self: center;
          align-self: center;
}
.miYVi h1
{
  padding: 20px;
  border-bottom: 1px solid gray;
}
.icon
{
  height: 30px;
}
.textoH
{
  width: 95%;
  margin: auto;
  color: white;
  font-weight: bolder;
  font-size: 50px;
  text-align: center;
}
.espaciado
{
  height: 90px;
  background-color: #2b4264;
}
.somos
{
  height: 100vh;  
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  background:url(/static/media/10600285Master.7596a592.jpg);
  -webkit-filter: hue-rotate(-25deg)  brightness(0.7);
          filter: hue-rotate(-25deg)  brightness(0.7);
}
.textoPa
{
  font-size: 25px;
  width: 90%;
  margin: auto;
  color:white;
  
}
.txtf
{
  opacity: 1 !important;
  border: solid 1px white;
}
.contactenos
{
  width: 100%;
  margin: auto;
  display: block;
  background-color:#385B97;
  height: 100vh;
}
.entro
{
  width: 90%;
  margin: auto;
}
.nosotros
{ 
  width: 100%;
  margin: auto;
  height: 100vh;
  display: block;
  background-color: #385B97;
}
.active{background-color: rgba(255, 255, 255, 0.2); }
.navButton{
  text-align: center;
  color: whitesmoke;
  font-weight: bold;
  font-size: 14px;
  -webkit-align-self: center;
          align-self: center;
  transition: all .25s; 
  padding: 10px;
  border-radius: 10px;
}
.button
{
  padding: 5px;
  font-size: 20px;
  background-color: #2d8ccb;
  border: 1px solid #2d8ccbb9;
  width: 200px;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  color: white;  
}
.button:hover
{ 
  background-color: #0099ff;
  cursor: pointer;
}
.navButton:hover
{
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.15);
}
.tarifas
{
  width: 100%;
  margin: auto;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  background-color: #2b4264;
}
@media screen and (max-width:1200px)
{
  .logo{height: 30px;}
  .navButton{font-size: 12px;}
  .tituloP
  {
    font-size: 25px;
  }
  .textoH
  {
  width: 95%;
  margin: auto;
  color: aliceblue;
  font-weight: bolder;
  font-size: 40px;
  text-align: center;
  
  }
  .background{height: auto; min-height:100vh;}
  .somos{height: auto; min-height:100vh; display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; -webkit-justify-content: space-around; justify-content: space-around;}
  .nosotros{display: block;  height: auto; min-height:100vh;}
  .tarifas{display: block; height: auto; min-height:100vh;}
  .contactenos{height: auto; padding-bottom: 5px; min-height:100vh;}
  .textoPa{font-size: 21px;} 
  .miYVi{width: 85%; margin: auto; padding: 15px; font-size: 23px;}

}

.area{
  width: 100%;
  height:98%;
  position: absolute; 
  overflow: hidden; 
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}



@-webkit-keyframes animate {

  0%{
      -webkit-transform: translateY(0) rotate(0deg);
              transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      -webkit-transform: translateY(-1000px) rotate(720deg);
              transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}



@keyframes animate {

  0%{
      -webkit-transform: translateY(0) rotate(0deg);
              transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      -webkit-transform: translateY(-1000px) rotate(720deg);
              transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}
.icono{height: 90px; -webkit-align-self:baseline; align-self:baseline;}
